<template>
  <click-to-edit
    v-model="localValue"
    :disabled="!isFieldAvailableForEdit(scope.row, 'scale.unit_id')"
    type="select"
    select-filterable
    :select-options="scalesUnits"
    select-option-id="id"
    select-option-name="symbol"
    @opened="remoteMethod"
    @input="saveElementPropChange(scope.row, 'scale', {unit_id:$event})"
  >
  </click-to-edit>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'element-library-unit-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {
      localValue: null,
      scalesUnits: [],
    }
  },
  watch: {
    'scope.row.scale.unit': function (newVal) {
      this.scalesUnits = [];
      this.scalesUnits.push(this.scope.row.scale.unit);
      this.localValue = this.scope.row.scale.unit.id;
    }
  },
  computed: {},
  mounted() {
    if( this.scope.row.scale.unit ){
      this.scalesUnits.push(this.scope.row.scale.unit);
      this.localValue = this.scope.row.scale.unit.id;
    }
  },
  methods: {
    remoteMethod() {
      this.$api.library.getScalesUnits()
        .then(data => {
          this.scalesUnits = data.scales_units;
        })
    },

  }
}

</script>

<style>

</style>
